import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { ModalEnum } from '../../ModalEnum';
import RouteEnum from '../../RouteEnum';
import { openModal } from '../../store/app';
import IndexPage from '../index';

export default function SignInPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(openModal(ModalEnum.SIGN_UP, { redirect: RouteEnum.HOMEPAGE }));
  }, []);

  return <IndexPage />;
}
